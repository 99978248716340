import React from 'react';
import {SEO, MainLayout} from '../components';

export default function About() {
	return (
		<>
			<SEO title="404: Not Found" />
			<div id="portal-root" />
			<MainLayout title="404" />
		</>
	);
}
